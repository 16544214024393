var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answer-table"},[_c('div',{staticClass:"answer-box-main"},[_c('b-card',[_c('b-card-header',{staticClass:"header d-flex justify-content-between"},[_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("g.student")))]),_c('div',[_vm._v(_vm._s(_vm.$t("g.date")))]),_c('div',[_vm._v(_vm._s(_vm.$t("g.final_score")))]),_c('div',[_vm._v(_vm._s(_vm.$t("g.no_submissions")))])])],1),_vm._l((_vm.page_data),function(item,index){return _c('b-card',{key:index,staticClass:"answer-item"},[_c('b-card-header',{staticClass:"d-flex justify-content-between card-header",on:{"click":function($event){return _vm.toggleCollapse(index)}}},[_c('div',{staticClass:"item-data"},[_c('b-img',{staticClass:"img",attrs:{"src":item.user.media[0]
                ? item.user.media[0].path
                : _vm.$helpers.uiAvatar(item.user.first_name)}}),_vm._v(_vm._s(item.user.first_name + " " + item.user.last_name)+" ")],1),_c('div',{staticClass:"item-data text-center"},[_vm._v(" "+_vm._s(_vm.formate(item.created_at))+" ")]),_c('div',{staticClass:"scores item-data text-center"},[_c('span',[_vm._v(_vm._s(item.assignment_submissions.final_score))])]),_c('div',{staticClass:"item-data custom-item text-center"},[_vm._v(" "+_vm._s(item.assignment_submissions.submissions.length)+" "),_c('b-img',{class:_vm.activeCollapse === index ? 'active' : null,attrs:{"src":require("@/assets/images/icons/arrow.png")}})],1)]),_c('b-collapse',{attrs:{"visible":_vm.activeCollapse === index,"c0-lass":"my-2"}},_vm._l((item.assignment_submissions
            .submissions),function(answer,index_answer){return _c('div',{key:answer.id,staticClass:"answer-box"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[(item.enrollments[0])?_c('b-link',{attrs:{"to":{
                  name: 'ShowSubmission',
                  params: {
                    enrollment_id: item.enrollments[0]
                      ? item.enrollments[0].id
                      : '',
                    assignment_id: _vm.$route.params.id,
                    submission_name: index_answer + 1,
                    submission_id: answer.id,
                  },
                  query: { course_id: _vm.course_id },
                }}},[_vm._v(" "+_vm._s(((_vm.$t("g.submission")) + " " + (index_answer + 1)))+" ")]):_vm._e()],1),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formate(answer.submit_date)))]),_c('div',{staticClass:"scores item-data text-center"},[_c('span',[_vm._v(_vm._s(answer.submission_grade ? answer.submission_grade.score : null))])])]),(
              index_answer !=
              item.assignment_submissions.submissions.length - 1
            )?_c('hr'):_vm._e()])}),0)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }